<template>
  <div class="w-100 h-100 p-3" ref="templatePDF">
    <h3 class="d-flex align-items-center justify-content-between">
      {{ service_scope.scope }}
    </h3>
    <div class="my-3">
      <strong class="d-block mb-2">Serviços</strong>
      <div class="d-flex flex-wrap">
        <span
          class="badge badge-info d-md-table mb-1 mr-1 text-truncate"
          v-for="(service, index) in service_scope.services"
          :key="index"
        >
          {{ service.activity }}
        </span>
      </div>
    </div>
    <div class="my-3">
      <strong>Período do serviço</strong>
      <div class="mt-2 text-muted d-flex align-items-center">
        <b-icon icon="calendar3" />
        <span class="mx-2">{{ service_scope.date_init | dateFull }}</span>
        <b-icon icon="arrow-right-short" />
        <span class="mx-2"> {{ service_scope.date_end | dateFull }}</span>
        <span class="mx-2"> ({{ service_scope.contract_months }} meses)</span>
      </div>
    </div>

    <div class="my-3">
      <strong>Valor deste contrato</strong>
      <div class="mt-2 text-muted">
        <div class="d-flex align-items-center">
          <b-icon icon="cash" />
          <span class="mx-2">
            Mensal:
            {{ service_scope.contract_value_mensal | money }}
          </span>
        </div>
        <div class="d-flex align-items-center mt-1">
          <b-icon icon="cash" />
          <span class="mx-2 d-block">
            Total:
            {{ service_scope.contract_value_total | money }}
          </span>
        </div>
      </div>
    </div>

    <div class="my-3">
      <strong>Faturamento mensal exigido do prestador</strong>
      <div class="mt-2 text-muted d-flex align-items-center">
        <b-icon icon="cash" />
        <span class="mx-2 d-block">
          {{ service_scope.monthly_invoicing_above | money }}
        </span>
      </div>
    </div>

    <div class="p-0" v-if="!is_provider">
      <strong class="d-block mb-2">Prestadores Convidados</strong>
      <b-table
        small
        striped
        :fields="fields"
        :items="service_scope.notifications"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    service_scope: {
      type: Object,
    },
  },
  data() {
    return {
      statusEmail: {
        1: "Pendente",
        2: "Aceito",
        3: "Cancelado",
        4: "Recusado",
      },
      fields: [
        { label: "E-mail", key: "email" },
        {
          label: "Data do envio",
          key: "created_at",
          formatter: (value) => this.moment(value).format("DD/MM/YY HH:mm"),
        },
        {
          label: "Expriado",
          key: "is_expired",
          formatter: (value) => (value ? "Sim" : "Não"),
        },
        {
          label: "Status",
          key: "status",
          formatter: (value) => this.statusEmail[value],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
  methods: {
    handleGeneratePdfSerivice() {},
  },
};
</script>

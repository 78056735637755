<template>
  <div>
    <hr class="m-0 border-light" />
    <Header />
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./Header";

export default {
  components: {
    Header,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["is_provider", "user"]),
  },
  methods: {
    ...mapActions(["hide_application_header"]),
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
  },
};
</script>
<style lang="scss">
.steps-validation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  .step {
    color: $bg-2;
    cursor: default;
    margin: 0;
    padding: 4px 8px 4px 30px;
    width: 100%;
    position: relative;
    background-color: $bg-2;
    color: $default;
    user-select: none;
    text-align: left;
    transition: background-color 0.2s ease;
    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid $bg-2;
      z-index: 2;
      transition: border-color 0.2s ease;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0px;
      right: -18.2px;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid $bg;
      z-index: 2;
      transition: border-color 0.2s ease;
      left: auto;
      z-index: 2;
    }
    &:first-child {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }
    &:last-child {
      &:before,
      &:after {
        border: none;
      }
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }
    span {
      position: relative;
      &:before {
        opacity: 0;
        position: absolute;
        top: -2px;
        left: -20px;
      }
    }
  }
  .step.done {
    background: $primary;
    color: $white;
    &:after {
      border-left-color: $primary;
    }

    span {
      &:before {
        opacity: 1;
        transition: opacity 0.3s ease 0.5s;
      }
    }
  }
  .step.current {
    color: $white;
    background-color: $secondary;
    &:after {
      border-left: 17px solid $secondary;
    }
    &:before {
      border: none;
    }
  }
}
.card-provider {
  display: flex;
  .item {
    flex: 1;
  }
}
</style>
